<template>
  <Layout>
		<v-row class="fill-height">
			<v-container>
				<v-row justify="center">
					<v-col cols="12" sm="3">
						<v-card class="rounded-card my-5" height='100%' elevation="2" dark color="grey darken-3">
							<v-card-text class="fill-height d-flex flex-column align-center justify-center" >
								<v-btn
									class="ma-2"
									outlined
									fab
									color="grey lighten-3"
									x-large
									@click.stop="newProject"
								>
									<v-icon size="40">mdi-plus</v-icon>
								</v-btn>
								<h2 class="mb-5">New Project</h2>

								<div class="my-5">
									<v-img src="@/assets/logo-top.png" contain height="40" />
								</div>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col v-if="loading" cols="12" sm="4" v-for="item in projects" :key="item._id" >
						<v-card
							class="rounded-card my-5 fill-height d-flex flex-column align-center justify-center"
							height='100%'
							elevation="2"
							dark
							color="primary darken-2"
						>
							<v-card-text class="d-flex flex-column align-center justify-center" >
                <h2 class="mb-5 white--text">Loading ... </h2>
              </v-card-text>
						</v-card>
					</v-col>
					<v-col v-if="!loading" cols="12" sm="4" v-for="item in projects" :key="item._id">
						<v-card
							class="rounded-card my-5 fill-height d-flex flex-column align-center justify-center"
							height='100%'
							elevation="2"
							dark
							color="primary darken-2"
						>
							<v-card-text class="d-flex flex-column align-center justify-center" >
                <h2 class="mb-5 white--text">{{item.title}} </h2>
								<h3>STATUS: {{item.status}}</h3>
								<v-btn
									v-if="item.status === 'READY-TO-TEST'"
									class="mt-4"
									dark color="secondary"
									@click="runTmodel(item)"
								>
									<v-icon large class="mx-2 white--text">
										mdi-check-network
									</v-icon>
									RUN TEST MODEL
								</v-btn>
              </v-card-text>
							<v-card-actions v-if="!item.default">
								<v-tooltip top>
									<span>Edit</span>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" v-on="on">
											<v-icon @click.stop="editProject(item)">
												mdi-file-edit
											</v-icon>
										</v-btn>
									</template>
								</v-tooltip>
								<v-tooltip top>
									<span>Delete</span>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" v-on="on">
											<v-icon size="30" @click="removeProject(item)">
												mdi-delete
											</v-icon>
										</v-btn>
									</template>
								</v-tooltip>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-row>



    <project-dialog @refreshData="getData" />
  </Layout>
</template>

<script>
  import axios from 'axios';
	import { mapState, mapActions } from "vuex";
	import store from '@/store'
	import ProjectDialog from '@/components/dialog/ProjectDialog.vue'
	const header = {"Token": store.state.authModule.token}
	const configuration = {headers: header}
  export default {
    name: 'Dashboard',
    components: { ProjectDialog },
    data: ()=>({
      projectsList: [],
      items: [],
			editedIndex: -1,
			editedItem: [{
				_id: '',
				user: {},
				title: '',
				description: '',
				dataSet: '',
				status: '',
				dataSet: []
			}],
			dataSet: [],
    }),
    mounted(){
			this.getData()
		},
    methods: {
    	...mapActions('projectModule',['getMyProjects','deleteProject']),
			async getData(){
							this.$store.commit('SET_LOADER', true, { root: true })
							const res = await this.getMyProjects(this.userId)
			},
			newProject(){
				this.$store.commit(
					'projectModule/SET_PROJECT',
					{
						_id: '',
						user: {},
						title: '',
						description: '',
						dataSet: '',
						status: '',
						dataSet: []
					},
					{ root: true }
				)
				this.$store.commit('SET_EDITMODE', 0)
				this.$store.commit('setDialog', true, { root: true })
			},
			editProject(item){
				this.editedItem = Object.assign({}, item)
				console.log('>>>>> ', item.user)
				this.editedItem.customer = item.user
				this.$store.commit('SET_EDITMODE', 1)
				this.$store.commit('projectModule/SET_PROJECT', this.editedItem, { root: true } )
				this.$store.commit('setDialog', true, { root: true })
			},
			async removeProject(item){
				await this.$confirm('Are you sure you want to delete this Project?', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
				})
				.then(res => {
					if (res){
						this.deleteProject(item)
						const index = this.projects.findIndex(x => x._id === item._id);
						if (item.dataSet.length>0){
							for (let i = 0; i < item.dataSet.length; i++) {
								axios.post('project/remove-file', {
									fileName: item.dataSet[i].fileName,
								}, configuration)
							}
						}
						this.projects.splice(index, 1);
					}
				})
			},
			runTmodel(item){
				this.$router.push({name: 'RunTmodelView', params: {id: item._id} })
			},
		},
    computed: {
			...mapState(['loading']),
			...mapState('projectModule',['projects','project']),
			userId(){
				return this.$store.state.authModule.user._id
			},
		},
		watch: {
			'projects': function () {
				this.projectsList = this.projects;
			}
		}
  }
</script>

<style scoped>
  .rounded-card{
    border-radius:25px;
    position: relative;
  }

</style>